import './css/main.css';

// import Plyr from 'plyr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js';


import barba from '@barba/core';

import { gsap } from "gsap";

import { customMap } from './mapbox';

var SmoothScroll = require('smooth-scroll');

let hamburger = document.getElementById("hamburger");


barba.hooks.before((data) => {
    var scrolltop = new SmoothScroll();
    scrolltop.animateScroll(0, {
      speed: 10,
      speedAsDuration: true
    });
    
  });
  
window.barba = barba;


barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0
        });
      },
      enter(data) {
        return gsap.from(data.next.container, {
          opacity: 0
        });
        
      },
      before(data) {
        // isoOpen();
        
        
      },
      once(data) {
        showNavCheck(data);
        customPlayer();
        customMap();
        talentFuncs();
        navHighlight(data);
        // workNavCheck(data);
        lightBg(data);
        mobileWorkHighlight(data);
        vidPause();
        videos(data);
      }
    }],
});





function navHighlight(data) {
    let navItems = document.getElementsByClassName("mainNavItem");
    let i;
    for (i = 0; i < navItems.length; i++) {
        let link = navItems[i].getAttribute("data-link");
        if (data.next.namespace === link) {
            navItems[i].classList.add("text-TpaAqua");
        } else {
            navItems[i].classList.remove("text-TpaAqua");
        };
    };
};


let mainContainer = document.getElementById("mainContainer");


function showNavCheck(data) {
    let nav = document.getElementById("mainNav");
    let footer = document.getElementById("footer");
    if (data.next.namespace === "video") {
        nav.classList.add("-translate-y-full");
        footer.classList.add("hidden");
        // setTimeout(function(){nav.classList.add("md:hidden")}, 500);
    } else {
        nav.classList.remove("-translate-y-full");
        footer.classList.remove("hidden");
        // setTimeout(function(){nav.classList.remove("opacity-0")}, 100);
    }
}




// Custom player
function customPlayer() {
    var players = document.getElementsByClassName("customPlayer");
    if (players.length > 0) {
    let player = new Plyr('#player', {
        controls: [
        // 'play-large', // The large play button in the center
        // 'restart', // Restart playback
        //       'rewind', // Rewind by the seek time (default 10 seconds)
            'play', // Play/pause playback
        //       'fast-forward', // Fast forward by the seek time (default 10 seconds)
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'captions', // Toggle captions
        //       'settings', // Settings menu
            'pip', // Picture-in-picture (currently Safari only)
            'airplay', // Airplay (currently Safari only)
        //       'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
            'fullscreen' // Toggle fullscreen
        ],
        autoplay: true,
        clickToPlay: true,
        fullscreen: { enabled: true, fallback: true, iosNative: true }
    });
}};

window.onload = function () {
    workNavCheck();
}

function talentFuncs() {
    var talent = document.getElementsByClassName("talentInner");
    if(talent.length > 0) {
        var i;
        var talent = document.getElementsByClassName("talentInner");
        for (i=0; i < talent.length; i++) {
            talent[i].addEventListener("mouseenter", function(event) {
                var video = event.target.querySelector("video");
                video.play();
            });
            talent[i].addEventListener("mouseleave", function(event) {
                var video = event.target.querySelector("video");
                video.pause();
            });
        };

    } else false;
};

function mobileMenuToggle(status) {
    var menu = document.getElementById("mobileMenu");
    console.log(status);
    if (status === "o") {
        menu.classList.remove("hidden");
        setTimeout(function(){document.getElementById("mobileMenu").classList.remove("opacity-0")}, 100);
        hamburger.classList.add("is-active");
        hamburger.setAttribute('data-currentstatus', 'o');
    } else {
        menu.classList.add("opacity-0");
        hamburger.classList.remove("is-active");
        setTimeout(function(){document.getElementById("mobileMenu").classList.add("hidden")}, 500);
        hamburger.setAttribute('data-currentstatus', 'c');
    };
};



hamburger.addEventListener("click", function(event) {
    let currentStatus = hamburger.getAttribute('data-currentstatus');
    if (currentStatus === 'o') {
        console.log('closing');
        mobileMenuToggle("c");
    } else {
        console.log('opening');
        mobileMenuToggle("o");
    };
});


var workButtonMob = document.getElementById("workButtonMob");
workButtonMob.addEventListener("click", function(event) {
    workNavCheck();
})

function workNavCheck(data) {
    let workSub = document.getElementById("workSubMenu");
    var status = workSub.getAttribute("data-status");
    // if (typeof data !== 'undefined' && data.next.namespace === "work") {
    //     var status = "c";
    // } else if (typeof data !== 'undefined') {
    //     var status = "o";
    // } else false;

    if (status === "o") {
        workSub.classList.add("opacity-0");
        setTimeout(function() {workSub.classList.add("hidden")}, 1500);
        setTimeout(function() {workSub.style.maxHeight = "0px"}, 500);
        setTimeout(() => {workSub.setAttribute("data-status", "c")}, 500);
    } else {
        workSub.classList.remove("hidden")
        setTimeout(function() {workSub.style.maxHeight = "1000px";}, 100);
        setTimeout(function() {document.getElementById("workSubMenu").classList.remove("opacity-0")}, 800);
        setTimeout(() => {workSub.setAttribute("data-status", "o")}, 500);
    }
}

// window.workNavCheck = workNavCheck;

function lightBg(data) {
    let body = document.getElementById("body");
    let footer = document.getElementById("footer");
    let hamLines = document.getElementsByClassName("hamburger-inner");
    let y;
    if (data.next.namespace === "about") {
        body.classList.remove("bg-TpaBlack");
        body.classList.add("bg-TpaWhite");
        footer.classList.remove("text-TpaWhite");
        footer.classList.add("footerLight");
        for (y = 0; y < hamLines.length; y++){
            hamLines[y].classList.add("light-ham");
        };
    } else {
        body.classList.add("bg-TpaBlack");
        body.classList.remove("bg-TpaWhite");
        footer.classList.add("text-TpaWhite");
        footer.classList.remove("footerLight");
        for (y = 0; y < hamLines.length; y++){
            hamLines[y].classList.remove("light-ham");
        };
    };
};

function mobileWorkHighlight(data) {
    let allItems = document.getElementsByClassName("mobileWorkCatItems");
    let h;
    for (h = 0; h < allItems.length; h++) {
        allItems[h].classList.remove("text-TpaAqua");
    };
    if (data.next.namespace === "work") {
        let workBlock = document.getElementById("workMainBlock");
        let currentCat = workBlock.getAttribute("data-currentcat");
        if (currentCat !== "all") {
            let highlightText = document.getElementById("mobileWorkCat-" + currentCat);
            highlightText.classList.add("text-TpaAqua");
        } else {
            document.getElementById("mobileWorkCat-all").classList.add("text-TpaAqua");
        };
    } else false;
};


function vidPause() {
    let vids = document.getElementsByClassName("twicVid");
    if (vids.length > 0) {
        
        let g;
        for(g=0; g < vids.length; g++){
            let vidgetId = vids[g].getAttribute("data-vidId");
            
            // let vidDiv = document.getElementById("vidDiv-" + vidgetId);
            let vidId = document.getElementById("video-" + vidgetId);
            // console.log("Playing: " + vidgetId);
            vidId.addEventListener('loadeddata', (e) => {
                // console.log("Playing: " + vidId);
                vidId.pause();
                if (vidId.readyState >= 3) {
                    vidId.pause()
                    setTimeout(function() {vidId.pause()}, 50);
                    setTimeout(function() {vidId.pause()}, 100);
                    console.log("Pausing: " + vidId);
                };
            });

            vidId.addEventListener('mouseover', (e) => {
                if (vidId.readyState >= 3) {
                    vidId.play();
                } else false;
            });

            vidId.addEventListener('mouseout', (e) => {
                vidId.pause();
            });
        };
    };
};

function videos(data) {
    import(/* webpackChunkName: "vidPlayer" */ "./js/components/vidPlayer.js").then(module => {
        const vidIt = module.default;
        vidIt(data, barba)
    });

    let vidPlayers = document.querySelectorAll('media-player');
    
    if (vidPlayers.length > 0) {
        let vd;
        for (vd=0; vd < vidPlayers.length; vd++) {
            vidPlayers[vd].addEventListener('provider-change', (event) => {
                const provider = event.detail;
                if (provider?.type === 'hls') {
                    console.log('HLS config change');
                    provider.config = { startLevel: 5 };
                };
            });
        };
    };
};


barba.hooks.beforeEnter((data) => {
    mobileMenuToggle("c");
});

barba.hooks.after((data) => {
    mobileWorkHighlight(data);
    vidPause();
    videos(data);
});

barba.hooks.enter((data) => {
    showNavCheck(data);
    customPlayer();
    customMap();
    talentFuncs();
    navHighlight(data);
    // workNavCheck(data);
    lightBg(data);
    vidPause();
});

