import mapboxgl from 'mapbox-gl';
import './css/mapbox-gl.css';

export function customMap() {
    var map = document.getElementById("map");
    if (typeof(map) != 'undefined' && map != null ) {

        mapboxgl.accessToken = 'pk.eyJ1IjoiZGV6YmF0IiwiYSI6IktBYTJ6UGcifQ.okynHEfjHSMnbH63aZEbvQ';
        const mapbox1 = new mapboxgl.Map({
            container: 'map', // container ID
            style: 'mapbox://styles/dezbat/cl4e02p36000c15p8on0xvdk1', // style URL
            center: [-0.134374, 51.520984], // starting position [lng, lat]
            zoom: 14 // starting zoom
        });

        mapbox1.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

        var markerpin = document.querySelector("#map-marker");

        const marker = new mapboxgl.Marker(
            {
                anchor: 'bottom',
                element: markerpin,
            })
        .setLngLat([-0.134374, 51.520984])
        .addTo(mapbox1);
        
        // var mapmarker = document.getElementById("map-marker");
        // const marker = new mapboxgl.Marker({
        //   color: "#FFFFFF",
        //   })
        // .setLngLat([0.024, 51.507])
        // .addTo(mapbox1);
        
    } else false;

};